import axios from "axios";
import { io } from "socket.io-client";
import vue from "vue";

export default vue.extend({
  data() {
    return {
      file: null as any,
      socketId: "",
      progress: 0,
      progressMax: 100,
      isUploading: false,
      pageProgress: {
        curr: 0,
        max: 0,
      },
      metaParsed: null as any,
    };
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },
  mounted() {
    const socket = io(process.env.VUE_APP_UPLOAD_API);
    var self: any = this;

    this.pageProgress = {
      curr: 0,
      max: 0,
    };
    this.progress = 0;
    this.metaParsed = null;

    socket.on("connect", function () {
      console.log("Connected");
      self.socketId = socket.id;
      console.log(self.socketId);
    });

    socket.on("uploadProgress", (data) => {
      this.progress = parseInt(data.progress);
      this.pageProgress.curr = data.currentPage;
      this.pageProgress.max = data.total;
    });

    socket.on("uploadDone", (data) => {
      this.metaParsed = data;
      this.isUploading = false;
    });

    socket.on("exception", function (data) {
      console.log("event", data);
    });

    socket.on("disconnect", function () {
      console.log("Disconnected");
    });
  },
  methods: {
    upload() {
      var fd = new FormData();
      fd.append("file", this.file);
      fd.append("socketId", this.socketId);

      this.isUploading = true;
      axios.post(`${process.env.VUE_APP_UPLOAD_API}/upload-api/upload`, fd).then((res) => {});
    },
  },
});
